import axios from "axios";

class UploadFileService {
  async uploadFile(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post<any>("/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
}

export default new UploadFileService();
