
import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  onMounted,
} from "vue";
import {
  MrUiKitInput,
  MrUiKitSelect,
  MrUiKitButton,
  //   MrUiKitToggle,
  MrUiKitDatePicker,
  MrUiKitCheckList,
} from "mr-ui-kit";
import useVuelidate from "@vuelidate/core";
import { FormInterface, PersonalDocument, Verification, FundsSource } from "@/interfaces";
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import * as _ from "lodash";
import UserService from "@/services/UserService";
import { useStore } from "vuex";

export default defineComponent({
  name: "Verification",
  components: {
    MrUiKitInput,
    MrUiKitSelect,
    MrUiKitButton,
    // MrUiKitToggle,
    MrUiKitCheckList,
    MrUiKitDatePicker
  },
  props: {
    form: {
      type: Object as PropType<FormInterface | null>,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    disableDraft: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["form-forward", "form-backward"],
  setup(props, { emit }: any) {
    const isMounted = ref(false);

    const store = useStore();

    const typePoliticalItems = store.getters.getTypePolitical;
    const rolePoliticalItems = store.getters.getRolePolitical;
    const documentEntity = store.getters.getDocumentEntity;
    const documentType = store.getters.getUserRequestDocumentType;
      const countriesFiltered = store.getters.getCountries.filter(
      (d: any) => d.supported
    );
          const countries = store.getters.getCountries;
    const fundsSource = store.getters.getFundsSource;

    const toggleItems = [
      { label: "Si", value: true },
      { label: "No", value: false },
    ];

    const manageModelSelect = () => {
      if (_.isNil(props.form)) return null;
      if (
        _.isNil(props.form.verification) ||
        _.isEmpty(props.form.verification)
      )
        return null;

      let verificationModel = _.cloneDeep(props.form.verification) as any;

      verificationModel.citizenship = countries.find(
        (d: any) => d.id === verificationModel.citizenship
      );

      verificationModel.personalDocument!.type = documentType.find(
        (d: any) => d.value === verificationModel.personalDocument!.type
      );
      verificationModel.personalDocument!.country = countries.find(
        (d: any) => d.id === verificationModel.personalDocument!.country
      );

      verificationModel.personalDocument!.releaseEntity = documentEntity.find(
        (d: any) =>
          d.value === verificationModel.personalDocument!.releaseEntity
      );

      verificationModel.countryMainActivity = countries.find(
        (d: any) => d.id === verificationModel.countryMainActivity
      );

             verificationModel.fundsSource = fundsSource.find(
          (d: any) => d.value === verificationModel.fundsSource
        );

      verificationModel.politicalPerson = toggleItems.find(
        (d: any) => d.value === verificationModel.politicalPerson
      );

      verificationModel.typePolitical = verificationModel.politicalPerson
        ? typePoliticalItems.find(
            (d: any) => d.value === verificationModel!.typePolitical
          )
        : null;

      verificationModel.rolePolitical = verificationModel.politicalPerson
        ? rolePoliticalItems.find(
            (d: any) => d.value === verificationModel!.rolePolitical
          )
        : null;

      return verificationModel;
    };

    const modelDocument = reactive<PersonalDocument>({
      type: null,
      number: "",
      releaseDate: "",
      country: null,
      releaseEntity: null,
    });

    const model: Verification = reactive<Verification>(
      manageModelSelect() || {
        mainActivity: "",
        countryMainActivity: null,
        citizenship: null,
        personalDocument: modelDocument,
        // fundsOrigin: "",
        fundsSource: "",
        politicalPerson: false,
        typePolitical: null,
        rolePolitical: null,
        notes: "",
      }
    );

    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );

    const toggleRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        const politicalPerson = model.politicalPerson as any;
        return politicalPerson.value ? !_.isNil(value) && value !== "" : true;
      }
    );

    const rulesConfig = reactive<any>({
      mainActivity: {
        required,
      },
      countryMainActivity: {
        required,
      },
      citizenship: {
        required,
      },

      personalDocument: {
        type: {
          required,
        },
        number: {
          required,
        },
        releaseDate: {
          required,
        },
        country: {
          required,
        },
        releaseEntity: {
          required,
        },
      },
        fundsSource: {
          required,
        },
      typePolitical: {
        toggleRequired,
      },
      rolePolitical: {
        toggleRequired,
      },
    });

    const allModels = computed(() => {
      return { ...model, ...modelDocument };
    });
    const v$ = useVuelidate(rulesConfig, allModels);

    const onUpdateFormItem = (item: string, itemNested?: string) => {
      if (itemNested) {
        (v$.value[item] as any)[itemNested].$touch();
      } else {
        v$.value[item].$touch();
      }
    };

    const onSubmit = async (isSave: boolean) => {
      if ((v$.value.$invalid && isSave) || (!isSave && props.disableDraft))
        return null;

      const verificationModel: any = _.cloneDeep(model) as any;


      verificationModel.fundsSource = _.has(
        verificationModel.fundsSource,
        "value"
      )
        ? verificationModel.fundsSource.value
        : verificationModel.fundsSource;

      switch (verificationModel.fundsSource) {
        case FundsSource.PersonalWorks: {
          verificationModel.employeeWorks = null;
          verificationModel.otherWorks = null;
          break;
        }
        case FundsSource.EmployeeWorks: {
          verificationModel.personalWorks = null;
          verificationModel.otherWorks = null;
          break;
        }
        case FundsSource.Other: {
          verificationModel.employeeWorks = null;
          verificationModel.personalWorks = null;
          break;
        }
        case FundsSource.Pension: {
          verificationModel.employeeWorks = null;
          verificationModel.personalWorks = null;
          verificationModel.otherWorks = null;
          break;
        }
      }


      verificationModel.citizenship = _.has(verificationModel.citizenship, "id")
        ? verificationModel.citizenship.id
        : verificationModel.citizenship;

      verificationModel.countryMainActivity = _.has(
        verificationModel.countryMainActivity,
        "id"
      )
        ? verificationModel.countryMainActivity.id
        : verificationModel.countryMainActivity;

      verificationModel.personalDocument.country = _.has(
        verificationModel.personalDocument.country,
        "id"
      )
        ? verificationModel.personalDocument.country.id
        : verificationModel.personalDocument.country;

      verificationModel.personalDocument.type = _.has(
        verificationModel.personalDocument.type,
        "value"
      )
        ? verificationModel.personalDocument.type.value
        : verificationModel.personalDocument.type;

      verificationModel.personalDocument.releaseEntity = _.has(
        verificationModel.personalDocument.releaseEntity,
        "value"
      )
        ? verificationModel.personalDocument.releaseEntity.value
        : verificationModel.personalDocument.releaseEntity;

                  verificationModel.fundsSource = _.has(
          verificationModel.fundsSource,
          "value"
        )
          ? verificationModel.fundsSource.value
          : verificationModel.fundsSource;
      // CHECK POLITICAL PERSON

      verificationModel.politicalPerson = _.has(
        verificationModel.politicalPerson,
        "value"
      )
        ? verificationModel.politicalPerson.value
        : verificationModel.politicalPerson;

      verificationModel.typePolitical = verificationModel.politicalPerson
        ? _.has(verificationModel.typePolitical, "value")
          ? verificationModel.typePolitical.value
          : null
        : null;

      verificationModel.rolePolitical = verificationModel.politicalPerson
        ? _.has(verificationModel.rolePolitical, "value")
          ? verificationModel.rolePolitical.value
          : null
        : null;

      emit(
        "form-forward",
        { form: { verification: verificationModel } },
        isSave
      );
    };

    const onBack = () => {
      emit("form-backward");
    };

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      v$,
      model,
      modelDocument,
      onBack,
      documentType,
      countries,
      typePoliticalItems,
      rolePoliticalItems,
      onSubmit,
      onUpdateFormItem,
      documentEntity,
      isMounted,
      isAdmin: UserService.isAdmin(),
      fundsSource,
      toggleItems,
      countriesFiltered
    };
  },
});
