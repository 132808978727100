
import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  onMounted,
} from "vue";
import { MrUiKitInput, MrUiKitSelect, MrUiKitButton } from "mr-ui-kit";
import useVuelidate from "@vuelidate/core";
import {
  EconomicOperator,
  FormInterface,
  LoanInfo,
  Prices,
} from "@/interfaces";
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import * as _ from "lodash";
import UserService from "@/services/UserService";
import { useStore } from "vuex";

export default defineComponent({
  name: "Verification",
  components: {
    MrUiKitInput,
    MrUiKitSelect,
    MrUiKitButton,
  },
  props: {
    form: {
      type: Object as PropType<FormInterface | null>,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    disableDraft: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["form-forward", "form-backward"],
  setup(props, { emit }: any) {
    const isMounted = ref(false);
    const store = useStore();

    const deadlineInstallment = store.getters.getDeadlineInstallment;

    const debitAccount = store.getters.getDebitAccount;

    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );

    onMounted(() => {
      isMounted.value = true;
    });

    const manageModelSelect = () => {
      if (_.isNil(props.form)) return null;
      if (_.isNil(props.form.loanInfo) || _.isEmpty(props.form.loanInfo))
        return null;

      let loanInfoModel: any = _.cloneDeep(props.form.loanInfo) as any;

      loanInfoModel.firstInstallmentDeadlineType = deadlineInstallment.find(
        (d: any) => d.value === loanInfoModel.firstInstallmentDeadlineType
      );

      loanInfoModel.debitAccount = debitAccount.find(
        (d: any) => d.value === loanInfoModel.debitAccount
      );

      return loanInfoModel;
    };

    const economicOperator = reactive<EconomicOperator>({
      name: store.getters.getUser.registeredName ?? store.getters.getUser.email ,
      address: store.getters.getUser.address + ' - ' + store.getters.getUser.city ,
    });

    const prices = reactive<Prices>({
      total: "",
      financeCost: "",
      preliminaryCost: "",
      additionalCost: "",
      otherAdditionalCost: "",
      monthlyPayment: "",
    });

    const model: LoanInfo = reactive<LoanInfo>(
      manageModelSelect() || {
        economicOperator,
        service: "",
        prices,
        firstInstallmentDeadlineType: null,
        // serviceOrigin: "",
        totalInstallment: "",
        tan: "",
        taeg: "",
        debitAccount: null,
        iban: "",
        headerCc: "",
      }
    );

    const rulesConfig = reactive<any>({
      economicOperator: {
        name: {
          required,
        },
        address: {
          required,
        },
      },
      service: {
        required,
      },
      prices: {
        total: {
          required,
        },
        financeCost: {
          required,
        },
        preliminaryCost: {
          required,
        },
        additionalCost: {
          required,
        },
        monthlyPayment: {
          required,
        },
      },
      firstInstallmentDeadlineType: {
        required,
      },
      totalInstallment: {
        required,
      },
      //   serviceOrigin: {
      //     required,
      //   },
      tan: {
        required,
      },
      taeg: {
        required,
      },
      debitAccount: {
        required,
      },
      iban: {
        required,
      },
      headerCc: {
        required,
      },
    });

    const allModels = computed(() => {
      return { ...model, ...prices, ...economicOperator };
    });
    const v$ = useVuelidate(rulesConfig, allModels);

    const onUpdateFormItem = (item: string, itemNested?: string) => {
      if (itemNested) {
        (v$.value[item] as any)[itemNested].$touch();
      } else {
        v$.value[item].$touch();
      }
    };

    const onSubmit = async (isSave: boolean) => {
      if ((v$.value.$invalid && isSave) || (!isSave && props.disableDraft))
        return null;
      const loanInfoModel: any = _.cloneDeep(model) as any;

      loanInfoModel.debitAccount = _.has(loanInfoModel.debitAccount, "value")
        ? loanInfoModel.debitAccount.value
        : loanInfoModel.debitAccount;
      loanInfoModel.firstInstallmentDeadlineType = _.has(
        loanInfoModel.firstInstallmentDeadlineType,
        "value"
      )
        ? loanInfoModel.firstInstallmentDeadlineType.value
        : loanInfoModel.firstInstallmentDeadlineType;

      emit("form-forward", { form: { loanInfo: loanInfoModel } }, isSave);
    };

    const onBack = () => {
      emit("form-backward");
    };

    return {
      v$,
      model,
      onBack,
      onSubmit,
      onUpdateFormItem,
      isMounted,
      deadlineInstallment,
      debitAccount,
      allModels,
      isAdmin: UserService.isAdmin(),
    };
  },
});
