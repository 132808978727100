import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "request-stepper mb-10-rem"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!
  const _component_MrUiKitStep = _resolveComponent("MrUiKitStep")!
  const _component_DocumentsSection = _resolveComponent("DocumentsSection")!
  const _component_MrUiKitStepper = _resolveComponent("MrUiKitStepper")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMounted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MrUiKitStepper, {
            orientation: 'vertical',
            modelValue: _ctx.currentStep,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStep) = $event))
          }, {
            verticalTemplate: _withCtx(() => [
              (_ctx.isAdmin || _ctx.currentStep === _ctx.steps.length - 1)
                ? (_openBlock(), _createBlock(_component_DocumentsSection, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
                return (_openBlock(), _createBlock(_component_MrUiKitStep, {
                  name: step.name,
                  subtitle: _ctx.currentStep === index ? step.subtitle : '',
                  key: index,
                  step: step.step,
                  disabled: _ctx.allowedStep < step.step
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MrUiKitCard, { headerClasses: ['header-bg-class', 'text-white', 'h3'] }, {
                      header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(step.name), 1)
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(step.component), {
                          form: _ctx.form,
                          isLocked: _ctx.isLocked,
                          disableDraft: step.step < _ctx.allowedStep,
                          onFormForward: _ctx.nextStep,
                          onFormBackward: _ctx.previousStep,
                          onFormSend: _ctx.sendForm
                        }, null, 8, ["form", "isLocked", "disableDraft", "onFormForward", "onFormBackward", "onFormSend"]))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["name", "subtitle", "step", "disabled"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 1,
          text: _ctx.spinnerText
        }, null, 8, ["text"]))
      : _createCommentVNode("", true)
  ], 64))
}