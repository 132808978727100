
import { defineComponent } from "vue";

export default defineComponent({
  name: "spinner",
  props: {
    text: {
      type: String,
      default: "Loading...",
    },
  },
  setup() {
    return {};
  },
});
