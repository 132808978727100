import { RequestDocument } from "@/interfaces";
import axios, { AxiosResponse } from "axios";
// import { saveAs } from 'file-saver';

class DocumentsService {
  async getDocuments(requestId: string | number): Promise<RequestDocument[]> {
    const { data }: AxiosResponse<RequestDocument[]> = await axios.get(
      "/users/requests/" + requestId + "/documents"
    );
    return data;
  }

  async downloadVerification(id: number | string, name: string): Promise<any> {
    const { data }: AxiosResponse<any> = await axios.post(
      "/documents/downloaded/" + id + "/" + name,
      {}
    );
    return data;
  }

  async downloadItem(url: string) {
    return axios.get(url, { responseType: "blob" });
  }
}

export default new DocumentsService();
