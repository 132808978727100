
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { MrUiKitStepper, MrUiKitStep } from "mr-ui-kit";
import Customer from "./steps/Customer.vue";
import Verification from "./steps/Verification.vue";
import LoanInfo from "./steps/LoanInfo.vue";
import CustomerInfo from "./steps/CustomerInfo.vue";
import OtherInfo from "./steps/OtherInfo.vue";
import DocumentsSection from "@/components/common/DocumentsSection.vue";
import UserService from "@/services/UserService";
import RequestService from "@/services/RequestService";
import { SponsorType, UserRequest } from "@/interfaces";
import DocNotes from "./steps/DocNotes.vue";
import Spinner from "@/components/UI/Spinner.vue";
import * as _ from "lodash";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "request-stepper",
  emits: ["reload", "sendId"],
  components: {
    MrUiKitStepper,
    MrUiKitStep,
    DocumentsSection,
    Spinner,
  },
  props: {
    request: {
      type: Object as PropType<UserRequest | null>,
      default: null,
    },
  },
  setup(props: any, { emit }: any) {
    const isMounted = ref(false);
    const store = useStore();
    const isLoading = ref(false);
    const spinnerText = ref("Loading");
    const isAdmin = UserService.isAdmin();
    const router = useRouter();
    const route = useRoute();

    const currentStep = ref(props.request ? props.request.allowedStep - 1 : 0);

    const allowedStep = computed(() => {
      return props.request ? props.request.allowedStep : 1;
    });

    const form = computed(() => {
      return props.request ? props.request.form ?? null : null;
    });

    const isLocked = computed(() => {
      return props.request ? props.request.isLocked ?? null : null;
    });

    watch(allowedStep, (newVal: number) => {
      currentStep.value = newVal - 1;
    });

    const requestModel =
      props.request ||
      reactive<UserRequest>({
        requestId: null,
        form: {},
        isLocked: null,
        currentStep: null,
        allowedStep: null,
        history: null,
        user: null,
        email: null,
        status: null,
      });

    const steps = [
      {
        name: "Anagrafica Cliente",
        subtitle: "Informazioni generali sul cliente",
        component: Customer,
        step: 1,
      },
      {
        name: "Adeguata Verifica",
        subtitle: "Dati richiesti per verifica fiscale",
        component: Verification,
        step: 2,
      },
      {
        name: "Prestissimo",
        subtitle: "Informazioni per calcolo mutuo",
        component: LoanInfo,
        step: 3,
      },
      {
        name: "Informazioni Cliente",
        subtitle: "Situazione familiare del cliente",
        component: CustomerInfo,
        step: 4,
      },
      {
        name: "Informazioni garante",
        subtitle: "Informazioni generali sul garante",
        component: OtherInfo,
        step: 5,
      },
      {
        name: "Note ed allegati",
        subtitle: "Note generali ed allegati richiesta",
        component: DocNotes,
        step: 6,
      },
    ];

    onMounted(() => {
      if (route.query.redirectRequestId) {
        router.push({
          name: "request",
          params: { id: route.query.redirectRequestId as string },
        });
      }
      isMounted.value = true;
    });

    const changeModelValeForFile = () => {
      const modelToSent = _.cloneDeep(requestModel);

      if (!_.isNil(modelToSent.form)) {
        if (
          !_.isNil(modelToSent.form.otherInfo) &&
          !_.isEmpty(modelToSent.form.otherInfo)
        ) {
          if (
            !_.isNil(modelToSent.form.otherInfo.sponsor) &&
            modelToSent.form.otherInfo.sponsor.type !== SponsorType.NoSponsor
          ) {
            modelToSent.form.otherInfo.identityCardSponsor = _.has(
              modelToSent.form.otherInfo.identityCardSponsor,
              "id"
            )
              ? modelToSent.form.otherInfo.identityCardSponsor.id
              : modelToSent.form.otherInfo.identityCardSponsor;
            modelToSent.form.otherInfo.paycheckSponsor = _.has(
              modelToSent.form.otherInfo.paycheckSponsor,
              "id"
            )
              ? modelToSent.form.otherInfo.paycheckSponsor.id
              : modelToSent.form.otherInfo.paycheckSponsor;
          }
        }

        if (
          !_.isNil(modelToSent.form.doc) &&
          !_.isEmpty(modelToSent.form.customerInfo)
        ) {
          modelToSent.form.customerInfo.identityCardCustomer = _.has(
            modelToSent.form.customerInfo.identityCardCustomer,
            "id"
          )
            ? modelToSent.form.customerInfo.identityCardCustomer.id
            : modelToSent.form.customerInfo.identityCardCustomer;

          modelToSent.form.customerInfo.paycheckCustomer = _.has(
            modelToSent.form.customerInfo.paycheckCustomer,
            "id"
          )
            ? modelToSent.form.customerInfo.paycheckCustomer.id
            : modelToSent.form.customerInfo.paycheckCustomer;
        }

        if (
          !_.isNil(modelToSent.form.doc) &&
          !_.isEmpty(modelToSent.form.doc)
        ) {
          modelToSent.form.doc.contract = _.has(
            modelToSent.form.doc.contract,
            "id"
          )
            ? modelToSent.form.doc.contract.id
            : modelToSent.form.doc.contract;

          modelToSent.form.doc["information-sheet"] = _.has(
            modelToSent.form.doc["information-sheet"],
            "id"
          )
            ? modelToSent.form.doc["information-sheet"].id
            : modelToSent.form.doc["information-sheet"];
          modelToSent.form.doc.privacy = _.has(
            modelToSent.form.doc.privacy,
            "id"
          )
            ? modelToSent.form.doc.privacy.id
            : modelToSent.form.doc.privacy;

          modelToSent.form.doc.crif = _.has(modelToSent.form.doc.crif, "id")
            ? modelToSent.form.doc.crif.id
            : modelToSent.form.doc.crif;

          modelToSent.form.doc.identityCardCustomer = _.has(
            modelToSent.form.doc.identityCardCustomer,
            "id"
          )
            ? modelToSent.form.doc.identityCardCustomer.id
            : modelToSent.form.doc.identityCardCustomer;
          modelToSent.form.doc["verification-doc"] = _.has(
            modelToSent.form.doc["verification-doc"],
            "id"
          )
            ? modelToSent.form.doc["verification-doc"].id
            : modelToSent.form.doc["verification-doc"];
        }
      }

      return modelToSent;
    };

    const nextStep = async (form: UserRequest, isSave: boolean) => {
      if (!isAdmin && !isLocked.value) {
        let message = "Dati salvati con successo";
        spinnerText.value = "Loading";

        if (!isSave) {
          message = "Bozza salvata con successo";
        }

        if (currentStep.value === 4 && isSave) {
          isLoading.value = true;
          spinnerText.value = "Stiamo generando i contratti";
          message = "Contratti generati con successo";
        }

        Object.assign(requestModel.form, form.form);

        const newModel = changeModelValeForFile();

        try {
          const response = await RequestService.makeRequest(
            newModel,
            currentStep.value + 1,
            isSave
          );

          if (!requestModel.requestId) {
            requestModel.requestId = response.id;
            router.push({ query: { redirectRequestId: response.id } });
          }

          if (allowedStep.value !== currentStep.value && isSave) {
            currentStep.value++;
          }

          store.commit("setRequestToggle");

          emit("sendId", requestModel.requestId);

          store.commit("setAlert", {
            type: "success",
            message: message,
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message:
              "Ops... c'è stato un errore. Verifica che tutti i campi siano compilati correttamente",
          });
        }
        isLoading.value = false;
      } else {
        currentStep.value++;
      }
    };

    const previousStep = () => {
      currentStep.value--;
    };

    const sendForm = async (form: UserRequest, status: string) => {
      Object.assign(requestModel.form, form.form);

      const newModel = changeModelValeForFile();

      newModel.history = isAdmin ? form.history : {};
      try {
        await RequestService.makeRequest(newModel, currentStep.value + 1, true);

        await RequestService.sendRequest(newModel, status);

        store.commit("setAlert", {
          type: "success",
          message: "Richiesta inviata correttamente",
        });

        store.commit("setRequestToggle");
      } catch (err: any) {
        store.commit("setAlert", {
          type: "danger",
          message:
            err.status === 404
              ? "Scarica tutti i documenti per poter procedere con la richiesta"
              : "Ops... c'è stato un errore.",
        });
      }
    };

    return {
      isMounted,
      currentStep,
      steps,
      isAdmin,
      form,
      isLocked,
      isLoading,
      spinnerText,
      nextStep,
      previousStep,
      sendForm,
      allowedStep,
    };
  },
});
