
import {
  defineComponent,
  PropType,
  reactive,
  onMounted,
  ref,
  computed,
  watch,
} from "vue";
import {
  FormInterface,
  OtherInfo,
  SponsorType,
  Sponsor,
  HouseType,
  Costs,
  FundsSource,
} from "@/interfaces";
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  MrUiKitInput,
  MrUiKitSelect,
  MrUiKitMultiUpload,
  MrUiKitAddField,
  MrUiKitCheckList,
  MrUiKitTextArea,
  MrUiKitDatePicker
} from "mr-ui-kit";
import * as _ from "lodash";
import FileUploadService from "@/services/FileUploadService";
import UserService from "@/services/UserService";
import UrlUtils from "@/utils/urlUtils";
import { useStore } from "vuex";

export default defineComponent({
  name: "other-info",
  emits: ["form-forward", "form-backward"],
  props: {
    form: {
      type: Object as PropType<FormInterface | null>,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    disableDraft: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MrUiKitInput,
    MrUiKitSelect,
    MrUiKitMultiUpload,
    MrUiKitAddField,
    MrUiKitCheckList,
    MrUiKitTextArea,
    MrUiKitDatePicker
  },
  setup(props, { emit }: any) {
    const isMounted = ref(false);

    const utils = new UrlUtils();

    const show = ref(false);

    const fileToShow = ref<any>([]);

    const store = useStore();

    const houseType = store.getters.getHouseType;

    const sponsorType = store.getters.getSponsorType;

    const fundsSource = store.getters.getFundsSource;

    const toggleItems = [
      { label: "Si", value: true },
      { label: "No", value: false },
    ];

    const manageModelSelect = () => {
      let otherInfoModel: any = null;

      if (_.isNil(props.form)) return null;
      if (_.isNil(props.form.otherInfo) || _.isEmpty(props.form.otherInfo))
        return null;

      otherInfoModel = _.cloneDeep(props.form.otherInfo) as any;

      otherInfoModel.sponsor.type = sponsorType.find(
        (d: any) => d.value === otherInfoModel.sponsor.type
      );
      otherInfoModel.sponsor.fundsSource = fundsSource.find(
        (d: any) => d.value === otherInfoModel.sponsor.fundsSource
      );

      otherInfoModel.sponsor.house = houseType.find(
        (d: any) => d.value === otherInfoModel.sponsor.house
      );

      otherInfoModel.sponsor.otherFinancial = toggleItems.find(
        (d: any) => d.value === otherInfoModel.sponsor.otherFinancial
      );

      return otherInfoModel;
    };

    const manageFiles = () => {
      if (_.isNil(props.form)) return null;
      if (_.isNil(props.form.otherInfo) || _.isEmpty(props.form.otherInfo))
        return null;

      if (props.form.otherInfo.sponsor.type === SponsorType.NoSponsor)
        return null;

      const filesModel: any = {
        paycheckSponsor: _.cloneDeep(
          props.form.otherInfo.paycheckSponsor
        ) as any,
        identityCardSponsor: _.cloneDeep(
          props.form.otherInfo.identityCardSponsor
        ) as any,
      };

      for (const [key, value] of Object.entries(filesModel)) {
        if (value) {
          const val = value as any;
          Object.assign(value, { name: val.filename ?? null });
          filesModel[key] = [{ file: value }];
        }
      }

      return filesModel;
    };

    const fileModel = reactive(
      manageFiles() || {
        identityCardSponsor: [],
        paycheckSponsor: [],
      }
    );

    const intermiadiateTemplate = ref({
      debit: "",
      totalMonthly: "",
      deadline: null,
    });

    const costs = reactive<Costs[]>([
      {
        debit: "",
        totalMonthly: "",
        deadline: null,
      },
    ]);

    const sponsor = reactive<Sponsor>({
      type: null,
      name: "",
      lastname: "",
      house: null,
      totalMonthlyHouseCost: "",
      fundsSource: null,
      notes: "",
      //   workActivity: "",
      personalWorks: "",
      employeeWorks: "",
      otherWorks: "",
      otherBanks: "",
      monthlySalary: "",
      otherFinancial: false,
      costs,
    });

    const modelEmpty: OtherInfo = {
      sponsor,
      notes: "",
      identityCardSponsor: null,
      paycheckSponsor: null,
    };

    const model = reactive<OtherInfo>(
      manageModelSelect() || _.cloneDeep(modelEmpty)
    );

    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );

    const sponsorRequired = helpers.withMessage(
      "Campo Obbligatorio",

      (value: any) => {
        return model.sponsor.type.value !== SponsorType.NoSponsor
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    const fieldsRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        const otherFinancial = model.sponsor.otherFinancial as any;
        return otherFinancial.value
          ? value.length &&
              value.every(
                (val: Costs) =>
                  val.debit !== "" &&
                  val.totalMonthly !== "" &&
                  val.deadline !== "" && val.deadline !== null
              )
          : true;
      }
    );

    const houseRentRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        return model.sponsor.house &&
          model.sponsor.house.value === HouseType.Rent
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    const otherRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        return model.sponsor.house &&
          model.sponsor.house.value === HouseType.Other
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    const fundsPersonalWorks = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        const m = model as any;
        return m.sponsor.fundsSource && m.sponsor.fundsSource.value === FundsSource.PersonalWorks
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    const fundsEmployeeWorks = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        let m = _.cloneDeep(model) as any;
        return m.sponsor.fundsSource &&
          m.sponsor.fundsSource.value === FundsSource.EmployeeWorks
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );
    const fundsOther = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        let m = _.cloneDeep(model) as any;
        return m.sponsor.fundsSource &&
          m.sponsor.fundsSource.value === FundsSource.Other
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    onMounted(() => {
      isMounted.value = true;
    });

    const rulesConfig = reactive<any>({
      sponsor: {
        type: {
          required,
        },
        name: {
          sponsorRequired,
        },
        lastname: {
          sponsorRequired,
        },
        house: {
          sponsorRequired,
        },
        totalMonthlyHouseCost: {
          houseRentRequired,
        },
        notes: {
          otherRequired,
        },
        fundsSource: {
          sponsorRequired,
        },

        personalWorks: {
          fundsPersonalWorks,
        },
        employeeWorks: {
          fundsEmployeeWorks,
        },
        otherWorks: {
          fundsOther,
        },
        monthlySalary: {
          sponsorRequired,
        },
        costs: {
          fieldsRequired,
        },
      },
      identityCardSponsor: {
        sponsorRequired,
      },
      paycheckSponsor: {
        sponsorRequired,
      },
    });

    const v$ = useVuelidate(rulesConfig, model);

    const onUpdateFormItem = (item: string, itemNested?: string) => {
      if (itemNested) {
        (v$.value[item] as any)[itemNested].$touch();
      } else {
        v$.value[item].$touch();
      }
    };

    const onUpdateFormItemCosts = (index: string, item: string) => {
      (v$.value["sponsor"] as any)["costs"].$touch();
    };

    const onSubmit = async (isSave: boolean) => {
      if ((v$.value.$invalid && isSave) || (!isSave && props.disableDraft))
        return null;

      let otherInfoModel: any;

      const typeOfSponsor = _.has(model.sponsor.type, "value")
        ? model.sponsor.type.value
        : model.sponsor.type;
      if (typeOfSponsor !== SponsorType.NoSponsor) {
        otherInfoModel = _.cloneDeep(model) as any;

        otherInfoModel.sponsor.type = typeOfSponsor;
        otherInfoModel.sponsor.fundsSource = _.has(
          otherInfoModel.sponsor.fundsSource,
          "value"
        )
          ? otherInfoModel.sponsor.fundsSource.value
          : otherInfoModel.sponsor.fundsSource;

        // switch (otherInfoModel.sponsor.fundsSource) {
        //   case FundsSource.PersonalWorks: {
        //     otherInfoModel.sponsor.employeeWorks = null;
        //     otherInfoModel.sponsor.otherWorks = null;
        //     break;
        //   }
        //   case FundsSource.EmployeeWorks: {
        //     otherInfoModel.sponsor.personalWorks = null;
        //     otherInfoModel.sponsor.otherWorks = null;
        //     break;
        //   }
        //   case FundsSource.Other: {
        //     otherInfoModel.sponsor.employeeWorks = null;
        //     otherInfoModel.sponsor.personalWorks = null;
        //     break;
        //   }
        //   case FundsSource.Pension: {
        //     otherInfoModel.sponsor.employeeWorks = null;
        //     otherInfoModel.sponsor.personalWorks = null;
        //     otherInfoModel.sponsor.otherWorks = null;
        //     break;
        //   }
        // }

        otherInfoModel.sponsor.house = _.has(
          otherInfoModel.sponsor.house,
          "value"
        )
          ? otherInfoModel.sponsor.house.value
          : otherInfoModel.sponsor.house;

        if (otherInfoModel.sponsor.house !== "rent") {
          otherInfoModel.sponsor.totalMonthlyHouseCost = null;
        }

        if (otherInfoModel.sponsor.house !== "other") {
          otherInfoModel.sponsor.notes = null;
        }

        otherInfoModel.sponsor.otherFinancial = otherInfoModel.sponsor
          .otherFinancial
          ? _.has(otherInfoModel.sponsor.otherFinancial, "value")
            ? otherInfoModel.sponsor.otherFinancial.value
            : null
          : null;

        if (!otherInfoModel.sponsor.otherFinancial) {
          otherInfoModel.sponsor.costs = [];
        }
      } else {
        otherInfoModel = _.cloneDeep(modelEmpty);

        otherInfoModel.sponsor.type = SponsorType.NoSponsor;
      }

      emit("form-forward", { form: { otherInfo: otherInfoModel } }, isSave);
    };

    const onBack = () => {
      emit("form-backward");
    };

    const onUpload = async ({ file: { index, file }, field }: any) => {
      const uploaded = await FileUploadService.uploadFile(file);

      model[field as keyof OtherInfo] = uploaded.id;
    };

    const fileNameClicked = (item: any) => {
      const url: any = utils.validateURL(item.file.url);
      fileToShow.value = [{ src: url, type: "pdf" }];
      show.value = true;
    };

    const showRent = computed(() => {
      if (!model.sponsor.house) return null;
      let m = model.sponsor.house as any;
      return m.value === "rent";
    });

    const showOther = computed(() => {
      if (!model.sponsor.house) return null;
      let m = model.sponsor.house as any;
      return m.value === "other";
    });

    return {
      v$,
      model,
      fileModel,
      isMounted,
      onSubmit,
      onBack,
      onUpdateFormItem,
      onUpdateFormItemCosts,
      sponsorType,
      onUpload,
      fileNameClicked,
      isAdmin: UserService.isAdmin(),
      showRent,
      fileToShow,
      show,
      houseType,
      intermiadiateTemplate,
      SponsorType,
      FundsSource,
      fundsSource,
      showOther,
      toggleItems,
    };
  },
});
