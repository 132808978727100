
import {
  defineComponent,
  ref,
  reactive,
  PropType,
  toRefs,
  computed,
} from "vue";
import {
  MrUiKitInput,
  MrUiKitSelect,
  MrUiKitButton,
  MrUiKitCheckList,
  MrUiKitDatePicker,
} from "mr-ui-kit";
import useVuelidate from "@vuelidate/core";
import { Customer, FormInterface, UserRequest } from "@/interfaces";
import * as validators from "@vuelidate/validators";
import { email } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import * as _ from "lodash";
import UserService from "@/services/UserService";
import { useStore } from "vuex";

export default defineComponent({
  name: "customer",
  components: {
    MrUiKitInput,
    MrUiKitSelect,
    MrUiKitButton,
    MrUiKitCheckList,
    MrUiKitDatePicker,
  },
  emits: ["form-forward"],
  props: {
    form: {
      type: Object as PropType<FormInterface | null>,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    disableDraft: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }: any) {
    const store = useStore();
    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );

    const toggleRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        let sameResidenceAddress = model.sameResidenceAddress as any;
        return !sameResidenceAddress.value
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    const toggleItems = [
      { label: "Si", value: true },
      { label: "No", value: false },
    ];

    const countries = store.getters.getCountries.filter(
      (d: any) => d.supported
    );

    const manageModelSelect = () => {
      if (_.isNil(props.form)) return null;
      if (_.isNil(props.form.customer) || _.isEmpty(props.form.customer))
        return null;

      const customerModel = _.cloneDeep(props.form.customer) as any;

      customerModel.country = countries.find(
        (d: any) => d.id === customerModel.country
      );

      customerModel.domicileCountry = countries.find(
        (d: any) => d.id === customerModel.domicileCountry
      );

      customerModel.sameResidenceAddress = toggleItems.find(
        (d: any) => d.value === customerModel.sameResidenceAddress
      );
      return customerModel;
    };

    const model = reactive<Customer>(
      manageModelSelect() || {
        name: "",
        lastname: "",
        dateOfBirth: "",
        birthCity: "",
        city: "",
        province: "",
        address: "",
        houseNumber: "",
        cap: "",
        sameResidenceAddress: null,
        domicileAddress: "",
        domicileHouseNumber: "",
        domicileCity: "",
        domicileProvince: "",
        domicileCap: "",
        domicileCountry: null,
        country: null,
        fiscalCode: "",
        telephone: "",
        email: "",
      }
    );

    const rulesConfig = reactive<any>({
      name: {
        required,
      },
      lastname: {
        required,
      },
      dateOfBirth: {
        required,
      },
      city: {
        required,
      },
      address: {
        required,
      },
      houseNumber: {
        required,
      },
      birthCity: {
        required,
      },
      province: {
        required,
      },
      cap: {
        required,
      },
      domicileAddress: {
        toggleRequired,
      },
      domicileHouseNumber: {
        toggleRequired,
      },
      domicileCity: {
        toggleRequired,
      },
      domicileProvince: {
        toggleRequired,
      },
      domicileCap: {
        toggleRequired,
      },
      domicileCountry: {
        toggleRequired,
      },
      country: {
        required,
      },
      fiscalCode: {
        required,
      },
      telephone: {
        required,
      },
      email: {
        email: helpers.withMessage("Insersci una email valida", email),
      },
    });

    const v$ = useVuelidate(rulesConfig, toRefs(model));

    const onUpdateFormItem = (item: string) => {
      v$.value[item].$touch();
    };

    const setResidenceAsHouse = (sameResidenceAddress: boolean | null) => {
      return {
        domicileAddress: sameResidenceAddress
          ? model.address
          : model.domicileAddress,
        domicileHouseNumber: sameResidenceAddress
          ? model.houseNumber
          : model.domicileHouseNumber,
        domicileCity: sameResidenceAddress ? model.city : model.domicileCity,
        domicileProvince: sameResidenceAddress
          ? model.province
          : model.domicileProvince,
        domicileCap: sameResidenceAddress ? model.cap : model.domicileCap,
        domicileCountry: sameResidenceAddress
          ? model.country
          : model.domicileCountry,
      };
    };

    const onSubmit = async (isSave: boolean) => {
      if ((v$.value.$invalid && isSave) || (!isSave && props.disableDraft))
        return null;

      let customerModel: any = _.cloneDeep(model) as any;

      customerModel.sameResidenceAddress = _.has(
        customerModel.sameResidenceAddress,
        "value"
      )
        ? customerModel.sameResidenceAddress.value
        : customerModel.sameResidenceAddress;

      customerModel = {
        ...customerModel,
        ...setResidenceAsHouse(customerModel.sameResidenceAddress),
      };

      customerModel.country = _.has(customerModel.country, "id")
        ? customerModel.country.id
        : customerModel.country;

      customerModel.domicileCountry = _.has(customerModel.domicileCountry, "id")
        ? customerModel.domicileCountry.id
        : customerModel.domicileCountry;

      emit("form-forward", { form: { customer: customerModel } }, isSave);
    };

    return {
      v$,
      model,
      countries,
      onUpdateFormItem,
      onSubmit,
      isAdmin: UserService.isAdmin(),
      toggleItems,
    };
  },
});
