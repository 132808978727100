import {
  Credentials,
  Tokens,
  LocalStorageKeys,
  VerifyEmail,
  UserRole,
} from "@/interfaces";
import store from "@/store";

class UserService {
  getUser = () => {
    return store.getters.getUser;
  };

  isAdmin(): boolean {
    return this.getUser().role === UserRole.Admininistrator;
  }

  isDealer(): boolean {
    return this.getUser().role === UserRole.Dealer;
  }

  isNoDealer(): boolean {
    return this.getUser().role === UserRole.NoDealer;
  }
}
export default new UserService();
