
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { MrUiKitTextArea, MrUiKitMultiUpload, MrUiKitBlob } from "mr-ui-kit";
import {
  Doc,
  FormInterface,
  RequestDocumentType,
  UserRequestStatus,
} from "@/interfaces";
import * as _ from "lodash";
import FileUploadService from "@/services/FileUploadService";
import UserService from "@/services/UserService";
import UrlUtils from "@/utils/urlUtils";
import { useStore } from "vuex";
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "doc-notes",
  components: {
    MrUiKitTextArea,
    MrUiKitMultiUpload,
    MrUiKitBlob,
  },
  props: {
    form: {
      type: Object as PropType<FormInterface | null>,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    disableDraft: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["form-forward", "form-backward", "form-send"],
  setup(props, { emit }: any) {
    const utils = new UrlUtils();
    const isMounted = ref(false);
    const show = ref(false);
    const fileToShow = ref<any>([]);

    const manageFile = () => {
      if (_.isNil(props.form)) return null;
      if (_.isNil(props.form.doc) || _.isEmpty(props.form.doc)) return null;

      let modelFile: any = _.cloneDeep(props.form?.doc) as any;

      const fModel = {
        contract: [],
        "information-sheet": [],
        // privacy: [],
        crif: [],
        "verification-doc": [],
      };

      for (const [key, value] of Object.entries({
        ...fModel,
        ...modelFile,
      })) {
        if (value && key !== "notes") {
          const val = value as any;

          if (val.id) {
            Object.assign(value, { name: val.filename });
            const el: any = { file: value } as any;
            modelFile[key as keyof Doc] = [el];
          } else {
            modelFile[key as keyof Doc] = [];
          }
        }
      }

      if (!showCrif.value) {
        delete modelFile.crif;
      }

      return modelFile;
    };

    const manageModelSelect = () => {
      if (_.isNil(props.form)) return null;
      if (_.isNil(props.form.doc) || _.isEmpty(props.form.doc)) return null;

      let modelDoc: any = _.cloneDeep(props.form?.doc) as any;

      modelDoc.contract = _.has(modelDoc.contract, "id")
        ? modelDoc.contract.id
        : null;

      modelDoc["information-sheet"] = _.has(modelDoc["information-sheet"], "id")
        ? modelDoc["information-sheet"].id
        : null;

      //   modelDoc.privacy = _.has(modelDoc.privacy, "id")
      //     ? modelDoc.privacy.id
      //     : null;
      modelDoc["verification-doc"] = _.has(modelDoc["verification-doc"], "id")
        ? modelDoc["verification-doc"].id
        : null;

      if (showCrif.value) {
        modelDoc.crif = _.has(modelDoc.crif, "id") ? modelDoc.crif.id : null;
      }

      return modelDoc;
    };

    const showCrif = computed(() => {
      if (_.isNil(props.form)) return false;
      if (_.isNil(props.form.customer) || _.isEmpty(props.form.customer))
        return false;

      return props.form.customer.country === 108 ? true : false;
    });

    const fileModel = reactive(
      manageFile() || {
        contract: [] as any,
        "information-sheet": [] as any,
        // privacy: [] as any,
        crif: [] as any,
        "verification-doc": [] as any,
      }
    );

    const model = reactive(
      manageModelSelect() || {
        notes: "",
        contract: null,
        "information-sheet": null,
        // privacy: null,
        crif: null,
        "verification-doc": null,
      }
    );

    const files = computed(() => {
      let allFiles = [
        "contract",
        "information-sheet",
        // "privacy",
        "verification-doc",
        "crif",
      ];

      if (!showCrif.value) {
        allFiles.splice(-1, 1);
      }

      return allFiles;
    });

    const onUpload = async ({ file: { index, file }, field }: any) => {
      const uploaded = await FileUploadService.uploadFile(file);
      model[field] = uploaded.id;
    };

    onMounted(() => {
      isMounted.value = true;
    });

    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );

    const rulesConfig = reactive<any>({
      contract: {
        required,
      },
      "information-sheet": {
        required,
      },
      //   privacy: {
      //     required,
      //   },
      "verification-doc": {
        required,
      },
      crif: {
        required,
      },
    });

    if (!showCrif.value) {
      delete rulesConfig.crif;
    }

    const v$ = useVuelidate(rulesConfig, model);

    const manageLabels = (type: string) => {
      let typeValue = "";
      switch (type) {
        case RequestDocumentType.CONTRATTO: {
          typeValue = "Contratto";
          break;
        }
        case RequestDocumentType.FOGLIO_INFORMATIVO: {
          typeValue = "Foglio Informativo";
          break;
        }
        case RequestDocumentType.PRIVACY: {
          typeValue = "Privacy";
          break;
        }
        case RequestDocumentType.CRIF: {
          typeValue = "CRIF";
          break;
        }
        case RequestDocumentType.ADEGUATA_VERIFICA: {
          typeValue = "Adeguata verifica";
          break;
        }
      }
      return typeValue;
    };

    const onSubmit = async () => {
      if (v$.value.$invalid) return null;
      emit(
        "form-send",
        { form: { doc: model } },
        UserRequestStatus.WaitingReview
      );
    };

    const onDraft = async (isSave: boolean) => {
      if (props.disableDraft) return null;
      emit("form-forward", { form: { doc: model } }, isSave);
    };

    const onBack = () => {
      emit("form-backward");
    };

    const fileNameClicked = (item: any) => {
      const url: any = utils.validateURL(item.file.url);
      fileToShow.value = [{ src: url, type: "pdf" }];
      show.value = true;
    };
    return {
      v$,
      isMounted,
      model,
      fileModel,
      files,
      isAdmin: UserService.isAdmin(),
      show,
      fileToShow,
      manageLabels,
      onSubmit,
      onBack,
      onDraft,
      onUpload,
      fileNameClicked,
      showCrif,
    };
  },
});
