import { HistoryMessage, RequestResponse, UserRequest } from "@/interfaces";
import axios, { AxiosResponse } from "axios";
import FilterService from "./FilterService";

class RequestService {
  async makeRequest(
    form: UserRequest,
    step: number,
    isSave: boolean
  ): Promise<any> {
    const { data }: AxiosResponse<any> = await axios.post(
      "/users/requests/save/" + step + "?isSave=" + isSave,
      form
    );
    return data;
  }

  async sendRequest(form: UserRequest, status: string): Promise<any> {
    const { data }: AxiosResponse<any> = await axios.post(
      "/users/requests/send/" + status,
      form
    );
    return data;
  }

  async getAllRequests(filter: any): Promise<RequestResponse[]> {
    const parsed = FilterService.parseFilter(filter);
    const { data }: AxiosResponse<RequestResponse[]> = await axios.get(
      "/users/requests" + "?" + parsed
    );
    return data;
  }

  async countRequests(filter: any): Promise<any> {
    const parsed = FilterService.parseFilter(filter);
    const { data }: AxiosResponse<any> = await axios.get(
      "/users/requests/count" + "?" + parsed
    );
    return data.count;
  }

  async getRequest(requestId: string | number): Promise<UserRequest> {
    const { data }: AxiosResponse<UserRequest> = await axios.get(
      "/users/requests/" + requestId
    );
    return data;
  }

  async getHistory(requestId: string | number): Promise<HistoryMessage[]> {
    const { data }: AxiosResponse<HistoryMessage[]> = await axios.get(
      "/users/requests/" + requestId + "/history"
    );
    return data;
  }

  async requestExport(): Promise<any> {
    const { data }: AxiosResponse<any> = await axios.get(
      "admin/users/requests/xlsx",
      {
        responseType: "blob",
      }
    );
    return data;
  }
}

export default new RequestService();
