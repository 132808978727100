
import DocumentsService from "@/services/DocumentsService";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import FileLink from "@/components/UI/FileLink.vue";
import { RequestDocument, RequestDocumentType } from "@/interfaces";
import { saveAs } from "file-saver";
import { useStore } from "vuex";

export default defineComponent({
  name: "documents-sections",
  components: {
    FileLink,
  },
  setup() {
    const isMounted = ref(false);
    const route = useRoute();
    const documents = ref<RequestDocument[]>([]);
    const requestId = ref<string>(
      (route.query.redirectRequestId as string) ?? (route.params.id as string)
    );
    const show = ref(false);
    const fileToShow = ref<any>([]);
    const store = useStore();
    onMounted(async () => {
      documents.value = await DocumentsService.getDocuments(requestId.value);
      isMounted.value = true;
    });

    const manageLabels = (type: string) => {
      let typeValue = "";
      switch (type) {
        case RequestDocumentType.CONTRATTO: {
          typeValue = "Contratto";
          break;
        }
        case RequestDocumentType.FOGLIO_INFORMATIVO: {
          typeValue = "Foglio Informativo";
          break;
        }
        case RequestDocumentType.PRIVACY: {
          typeValue = "Privacy";
          break;
        }
        case RequestDocumentType.CRIF: {
          typeValue = "CRIF";
          break;
        }
        case RequestDocumentType.ADEGUATA_VERIFICA: {
          typeValue = "Adeguata verifica";
          break;
        }
      }
      return typeValue;
    };

    const onClick = async (doc: RequestDocument) => {
      try {
        if (!doc.downloaded) {
          await DocumentsService.downloadVerification(
            (route.query.redirectRequestId as any) ?? requestId.value,
            doc.type
          );
          documents.value = await DocumentsService.getDocuments(
            (route.query.redirectRequestId as any) ?? requestId.value
          );
        }
        await DocumentsService.downloadItem(doc.url).then(
          async (response: any) => {
            saveAs(response.data, manageLabels(doc.type) + ".pdf");
          }
        );
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: "Ops... c'è stato un errore.",
        });
      }
    };

    return {
      isMounted,
      documents,
      show,
      fileToShow,
      manageLabels,
      onClick,
    };
  },
});
