
import {
  defineComponent,
  PropType,
  onMounted,
  ref,
  reactive,
  computed,
  h,
} from "vue";
import { Costs, CustomerInfo, FormInterface, FundsSource } from "@/interfaces";
import * as _ from "lodash";
import UserService from "@/services/UserService";
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  MrUiKitInput,
  MrUiKitSelect,
  MrUiKitAddField,
  MrUiKitCheckList,
  MrUiKitDatePicker
} from "mr-ui-kit";
import { useStore } from "vuex";
import FileUploadService from "@/services/FileUploadService";
import UrlUtils from "@/utils/urlUtils";

export default defineComponent({
  name: "customer-info",
  emits: ["form-forward", "form-backward"],
  props: {
    form: {
      type: Object as PropType<FormInterface | null>,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    disableDraft: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MrUiKitInput,
    MrUiKitSelect,
    MrUiKitAddField,
    MrUiKitCheckList,
    MrUiKitDatePicker
  },
  setup(props, { emit }: any) {
    const isMounted = ref(false);

    const show = ref(false);

    const fileToShow = ref<any>([]);

    const store = useStore();

    const utils = new UrlUtils();

    const houseType = store.getters.getHouseType;

    const customerFamilyType = store.getters.getCustomerFamilyType;

    // const fundsSource = store.getters.getFundsSource;

    const toggleItems = [
      { label: "Si", value: true },
      { label: "No", value: false },
    ];

    const required = helpers.withMessage(
      "Campo Obbligatorio",
      validators.required
    );

    const decimal = helpers.withMessage(
      "Solo valori numerici",
      validators.integer
    );

    const min = helpers.withMessage(
      "Minimo valore concesso: 0",
      validators.minValue(0)
    );

    const fieldsRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        const otherFinancial = model.otherFinancial as any;
        return otherFinancial.value
          ? value.length &&
              value.every(
                (val: Costs) =>
                  val.debit !== "" && 
                  val.totalMonthly !== "" &&
                  val.deadline !== "" &&  val.deadline !== null
              )
          : true;
      }
    );

    const houseRentRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        let m = _.cloneDeep(model) as any;
        return m.house.value === "rent"
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    const otherRequired = helpers.withMessage(
      "Campo Obbligatorio",
      (value: any) => {
        let m = _.cloneDeep(model) as any;
        return m.house.value === "other"
          ? !_.isNil(value) && value !== ""
          : true;
      }
    );

    // const fundsPersonalWorks = helpers.withMessage(
    //   "Campo Obbligatorio",
    //   (value: any) => {
    //     let m = _.cloneDeep(model) as any;
    //     return m.fundsSource.value === FundsSource.PersonalWorks
    //       ? !_.isNil(value) && value !== ""
    //       : true;
    //   }
    // );

    // const fundsEmployeeWorks = helpers.withMessage(
    //   "Campo Obbligatorio",
    //   (value: any) => {
    //     let m = _.cloneDeep(model) as any;
    //     return m.fundsSource.value === FundsSource.EmployeeWorks
    //       ? !_.isNil(value) && value !== ""
    //       : true;
    //   }
    // );
    // const fundsOther = helpers.withMessage(
    //   "Campo Obbligatorio",
    //   (value: any) => {
    //     let m = _.cloneDeep(model) as any;
    //     return m.fundsSource.value === FundsSource.Other
    //       ? !_.isNil(value) && value !== ""
    //       : true;
    //   }
    // );

    const manageModelSelect = () => {
      let customerInfoModel: any = null;

      if (_.isNil(props.form)) return null;
      if (
        _.isNil(props.form.customerInfo) ||
        _.isEmpty(props.form.customerInfo)
      )
        return null;

      customerInfoModel = _.cloneDeep(props.form.customerInfo) as any;

      customerInfoModel.familyType = customerFamilyType.find(
        (d: any) => d.value === customerInfoModel.familyType
      );

    //   customerInfoModel.fundsSource = fundsSource.find(
    //     (d: any) => d.value === customerInfoModel.fundsSource
    //   );

      customerInfoModel.house = houseType.find(
        (d: any) => d.value === customerInfoModel.house
      );

      customerInfoModel.otherFinancial = toggleItems.find(
        (d: any) => d.value === customerInfoModel.otherFinancial
      );

      return customerInfoModel;
    };

    const manageFiles = () => {
      if (_.isNil(props.form)) return null;
      if (
        _.isNil(props.form.customerInfo) ||
        _.isEmpty(props.form.customerInfo)
      )
        return null;

      let modelFile: any = _.cloneDeep(props.form?.customerInfo) as any;

      const fModel: any = {
        paycheckCustomer: [],
        identityCardCustomer: [],
      };

      Object.keys(modelFile)
        .filter((key: any) => key in fModel)
        .forEach((key) => {
          fModel[key] = modelFile[key] ?? [];
        });

      for (const [key, value] of Object.entries(fModel)) {
        if (value) {
          const val = value as any;

          if (val.id) {
            Object.assign(value, { name: val.filename });
            const el: any = { file: value } as any;
            fModel[key] = [el];
          } else {
            fModel[key] = [];
          }
        }
      }
      return fModel;
    };

    const fileModel = reactive(
      manageFiles() || {
        identityCardCustomer: [],
        paycheckCustomer: [],
      }
    );

    const intermiadiateTemplate = ref({
      debit: "",
      totalMonthly: "",
      deadline: null
    });

    const costs = reactive<Costs[]>([
      {
        debit: "",
        totalMonthly: "",
        deadline: null
      },
    ]);

    const model = reactive<CustomerInfo>(
      manageModelSelect() || {
        house: null,
        totalMonthlyHouseCost: "",
        familyType: null,
        totalChildren: 0,
        otherBanks: "",
        // fundsSource: "",
        notes: "",
        personalWorks: "",
        employeeWorks: "",
        otherWorks: "",
        monthlySalary: "",
        otherFinancial: null,
        costs,
        identityCardCustomer: null,
        paycheckCustomer: null,
      }
    );

    const rulesConfig = reactive<any>({
      house: {
        required,
      },
      totalMonthlyHouseCost: {
        houseRentRequired,
      },
      notes: {
        otherRequired,
      },
      familyType: {
        required,
      },
      totalChildren: {
        decimal,
        min,
      },
    //   fundsSource: {
    //     required,
    //   },
    //   personalWorks: {
    //     fundsPersonalWorks,
    //   },
    //   employeeWorks: {
    //     fundsEmployeeWorks,
    //   },
    //   otherWorks: {
    //     fundsOther,
    //   },
      monthlySalary: {
        required,
      },
      costs: {
        fieldsRequired,
      },
      identityCardCustomer: {
        required,
      },
      paycheckCustomer: {
        required,
      },
    });

    onMounted(() => {
      isMounted.value = true;
    });

    const allModels = computed(() => {
      return { ...model, ...costs };
    });

    const v$ = useVuelidate(rulesConfig, allModels);

    const onUpdateFormItem = (
      item: string,
      itemNested?: string,
      index?: number
    ) => {
      v$.value[item].$touch();
    };

    const onSubmit = async (isSave: boolean) => {
      if ((v$.value.$invalid && isSave) || (!isSave && props.disableDraft))
        return null;
      const customerInfoModel: any = _.cloneDeep(model) as any;

      customerInfoModel.familyType = _.has(
        customerInfoModel.familyType,
        "value"
      )
        ? customerInfoModel.familyType.value
        : customerInfoModel.familyType;

    //   customerInfoModel.fundsSource = _.has(
    //     customerInfoModel.fundsSource,
    //     "value"
    //   )
    //     ? customerInfoModel.fundsSource.value
    //     : customerInfoModel.fundsSource;

    //   switch (customerInfoModel.fundsSource) {
    //     case FundsSource.PersonalWorks: {
    //       customerInfoModel.employeeWorks = null;
    //       customerInfoModel.otherWorks = null;
    //       break;
    //     }
    //     case FundsSource.EmployeeWorks: {
    //       customerInfoModel.personalWorks = null;
    //       customerInfoModel.otherWorks = null;
    //       break;
    //     }
    //     case FundsSource.Other: {
    //       customerInfoModel.employeeWorks = null;
    //       customerInfoModel.personalWorks = null;
    //       break;
    //     }
    //     case FundsSource.Pension: {
    //       customerInfoModel.employeeWorks = null;
    //       customerInfoModel.personalWorks = null;
    //       customerInfoModel.otherWorks = null;
    //       break;
    //     }
    //   }

      customerInfoModel.house = _.has(customerInfoModel.house, "value")
        ? customerInfoModel.house.value
        : customerInfoModel.house;

      if (customerInfoModel.house !== "rent") {
        customerInfoModel.totalMonthlyHouseCost = null;
      }

      if (customerInfoModel.house !== "other") {
        customerInfoModel.notes = null;
      }

      customerInfoModel.totalChildren = Number(customerInfoModel.totalChildren);

      customerInfoModel.otherFinancial = customerInfoModel.otherFinancial
        ? _.has(customerInfoModel.otherFinancial, "value")
          ? customerInfoModel.otherFinancial.value
          : null
        : null;

      if (!customerInfoModel.otherFinancial) {
        customerInfoModel.costs = [];
      }

      emit(
        "form-forward",
        { form: { customerInfo: customerInfoModel } },
        isSave
      );
    };

    const onBack = () => {
      emit("form-backward");
    };

    const onUpload = async ({ file: { index, file }, field }: any) => {
      const uploaded = await FileUploadService.uploadFile(file);

      model[(field as "identityCardCustomer") || "paycheckCustomer"] =
        uploaded.id;
    };

    const fileNameClicked = (item: any) => {
      const url: any = utils.validateURL(item.file.url);
      fileToShow.value = [{ src: url, type: "pdf" }];
      show.value = true;
    };

    const showRent = computed(() => {
      if (!model.house) return null;
      let m = model.house as any;
      return m.value === "rent";
    });

    const showOther = computed(() => {
      if (!model.house) return null;
      let m = model.house as any;
      return m.value === "other";
    });

    return {
      v$,
      model,
      isMounted,
      houseType,
    //   fundsSource,
      customerFamilyType,
      onUpdateFormItem,
      intermiadiateTemplate,
      showRent,
      showOther,
      onSubmit,
      onBack,
      FundsSource,
      isAdmin: UserService.isAdmin(),
      toggleItems,
      onUpload,
      show,
      fileToShow,
      fileModel,
      fileNameClicked,
    };
  },
});
